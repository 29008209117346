




import Vue from 'vue'

export default Vue.extend({
  name: 'Progressbar',
  props: {
    count: {
      type: Number,
    },
    sum: {
      type: Number,
    }
  }
})
