














import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import AssessmentResult from '@/components/AssessmentResult.vue'
import Button from '@/components/Button.vue'
import Progressbar from '@/components/Progressbar.vue'

export default Vue.extend({
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (vm.$store.getters['user/orientationAnswers'].length === 0) {
        next('/orientation/1')
      }
    })
  },
  components: {
    AssessmentResult,
    Button,
    Progressbar,
  },
  data() {
    return {
      current: {},
      position: null,
      questionId: '',
    }
  },
  computed: {
    ...mapGetters('questions', [
      'orientationById',
      'displayPositionOrientation',
      'followingOrientationQuestion',
      'totalOrientationAmount'
    ]),
    ...mapGetters('user', [
      'answers'
    ]),
  },
  created() {
    this.questionId = this.$route.params.id
    this.current = this.orientationById(this.questionId)
  },
  methods: {
    ...mapActions('user', [
      'submitOrientationAnswer'
    ]),
    submit(value: String) {
      if (!this.followingOrientationQuestion(this.questionId)) {
        this.submitOrientationAnswer({
          value,
          question: this.current
        }).then(() => {
          this.$router.push({
              path: '/orientation/success',
            })
        })
      } else {
        this.submitOrientationAnswer({
          value,
          question: this.current
        }).then(() => {
          this.$router.push({
            path: this.followingOrientationQuestion(this.questionId).id.toString(),
          })
        })
      }
    }
  }
})
