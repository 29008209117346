//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import Button from '@/components/Button.vue'
import RichText from '@/components/RichText.vue'
import axios from 'axios'
import { marked } from 'marked'
import Autolinker from 'autolinker';

export default Vue.extend({
  components: {
    Button,
    RichText
  },
  async asyncData({ $http, params }) {
    const selected = await axios.get(
      `https://jobs-backend.teamnushu.de/api/jobs/preview/${params.id}`
    )
    return { selected: selected.data[0] }
  },
  data() {
    return {
      selected: undefined,
    }
  },
  mounted() {
    this.$ga.event({
      eventCategory: 'jobs',
      eventAction: 'aufruf',
      eventLabel: `${this.selected.company.name} | ${this.selected.title}`,
      eventValue: 1
    })
  },
  methods: {
    ...mapActions('jobs', [
      'fetchSingle',
    ]),
    _handleButtonClick() {
      this.$router.push('/')
    },
    addhttps(url) {
      if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
        url = "https://" + url;
      }
      return url;
    },
    formatText(content) {
      return Autolinker.link(marked(content))
    }
  },
})
