import axios from 'axios'

export const state = () => ({
  vacancies: [],
  matched: [],
  suggestions: [],
})

export const actions = {
  fetchAll: async (state) => {
    return axios.get(`https://jobs-backend.teamnushu.de/api/jobs/all`)
      .then(response => {
        state.commit('storeJobs', response.data.data)
      })
  },
  fetchSuggested: async (state) => {
    const level = state.rootState.user.orientationAnswers[0].value
    const specialty = state.rootState.user.orientationAnswers[1].value
    const values = state.rootState.user.core_values.join(',')
    return axios.get(`https://jobs-backend.teamnushu.de/api/jobs/newpath?values=${values}&level=${level}&field=${specialty}`).then(response => {
      state.commit('storeJobSuggestions', response.data[0])
    })
  },
  fetchMatched: async (state) => {
    const level = state.rootState.user.orientationAnswers[0].value
    const specialty = state.rootState.user.orientationAnswers[1].value
    const values = state.rootState.user.core_values.join(',')
    return axios.get(`{https://jobs-backend.teamnushu.de}/api/jobs?values=${values}&level=${level}&field=${specialty}`).then(response => {
      state.commit('storeMatched', response.data[0])
    })
  }
}

export const mutations = {
  storeJobs: (state, data) => {
    state.vacancies = data
  },
  storeMatched: (state, data) => {
    state.matched = data
  },
  storeJobSuggestions: (state, data) => {
    state.suggestions = data
  }
}

export const getters = {
  allJobs: (state) => {
    return state.vacancies
  },
  suggestedJobs: (state) => {
    return state.suggestions
  },
  matchedJobs: (state) => {
    return state.matched
  }
}
