






























import Vue from 'vue'

export default Vue.extend({
  name: 'JobCard',
  props: {
    job: {
      type: Object,
      required: true,
    },
    type: String
  },
  mounted() {
    if (this.type !== 'joblist-all') {
      // @ts-ignore
      this.$ga.event({
        eventCategory: 'jobs',
        eventAction: this.type,
        eventLabel: `${this.job.company.name} | ${this.job.title}`,
        eventValue: 1
      })
    }
  },
})
