import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'

export const state = () => ({
  id: uuidv4(),
  answers: [],
  orientationAnswers: [],
  result: '',
  core_values: '',
  weakness: ''
})

export const actions = {
  submitAnswer: async (state, data) => {
    state.commit('storeAnswer', { value: data.value, question: data.question.id })
  },
  submitOrientationAnswer: async (state, data) => {
    state.commit('storeOrientationAnswer', { value: data.value, question: data.question.id })
  },
  calculateResult: async (state) => {
    const values = state.getters.answers.map(answer => answer.value)
    return axios.get(`https://jobs-matching.teamnushu.de/api/match?answers=${values.join(',')}`)
      .then(response => {
        state.commit('storePersonalityResult', response.data.personality_match)
        state.commit('storeCoreValues', response.data.core_values)
        state.commit('storeWeakness', response.data.least_answered_value.toString())
      })
  },
}

export const mutations = {
  storeAnswer: (state, data) => {
    const wasAlreadyGiven = state.answers.find(answer => answer.question === data.question)
    if (!wasAlreadyGiven) {
      state.answers.push(data)
    } else {
      wasAlreadyGiven.value = data.value
    }
  },
  storeOrientationAnswer: (state, data) => {
    state.orientationAnswers.push(data)
  },
  storePersonalityResult: (state, data) => {
    state.result = data
  },
  storeCoreValues: (state, data) => {
    state.core_values = data
  },
  storeWeakness: (state, data) => {
    state.weakness = data
  },
}

export const getters = {
  answers: (state) => {
    return state.answers
  },
  orientationAnswers: (state) => {
    return state.orientationAnswers
  },
  result: (state) => {
    return state.result
  },
  weakness: (state) => {
    return state.weakness
  },
  core_values: (state) => {
    return state.core_values
  },
  newPath: (state) => {
    return state.orientationAnswers[2].value === 'futurejob_newpath'
  }
}
