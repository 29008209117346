




import Vue from 'vue'

export default Vue.extend({
  name: 'Button',
  props: {
    tag: {
      type: String,
      default: 'nuxt-link'
    },
    to: {
      type: String,
      default: '#'
    },
    targetBlank: {
      type: String,
      default: ''
    },
    variant: {
      type: String,
      default: 'primary'
    }
  }
})
