//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import AssessmentResult from '@/components/AssessmentResult.vue'
import Button from '@/components/Button.vue'
import RichText from '@/components/RichText.vue'
import Illustration from '@/components/Illustration.vue'
import { marked } from 'marked'
import Autolinker from 'autolinker';

export default Vue.extend({
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.$sentry.captureMessage('redirected to start of assessment')
      if (vm.$store.getters['user/answers'].length !== 20) {
        next('/assessment/1')
      }
    })
  },
  components: {
    AssessmentResult,
    Button,
    Illustration,
    RichText
  },
  async fetch() {
    await this.fetchAll()
    await this.fetchSuggested()
    await this.fetchMatched()
  },
  data() {
    return {
      selected: undefined
    }
  },
  computed: {
    ...mapGetters('user', [
      'result',
      'weakness',
      'newPath'
    ]),
    ...mapGetters('jobs', [
      'allJobs',
      'suggestedJobs',
      'matchedJobs',
    ]),
  },
  methods: {
    ...mapActions('jobs', [
      'fetchAll',
      'fetchSuggested',
      'fetchMatched',
    ]),
    _handleModalClose() {
      this.selected = undefined
    },
    _handleJobCardClick(job) {
      this.selected = job
      this.$ga.event({
        eventCategory: 'jobs',
        eventAction: 'aufruf',
        eventLabel: `${job.company.name} | ${job.title}`,
        eventValue: 1
      })
      this.$nextTick(() => {
        this.$modal.show('jobDetail')
      })
    },
    _trackApplyClick() {
      this.$ga.event({
        eventCategory: 'jobs',
        eventAction: 'bewerben-button-click',
        eventLabel: `${this.selected.company.name} | ${this.selected.title}`,
        eventValue: 1
      })
    },
    formatText(content) {
      return Autolinker.link(marked(content))
    },
    addhttps(url) {
      if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
        url = "https://" + url;
      }
      return url;
    }
  }
})
