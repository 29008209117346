

















import Vue from 'vue'

export default Vue.extend({
  name: 'NewsletterPopup',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleCloseIconClick() {
      this.$emit('close-newsletter-modal')
    }
  }
})
