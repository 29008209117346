//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import axios from 'axios'

export default Vue.extend({
  name: 'AssessmentResult',
  async fetch() {
    const response = await axios.get(`https://jobs-backend.teamnushu.de/api/result/personality/${this.result}`)
    const improvementResponse = await axios.get(`https://jobs-backend.teamnushu.de/api/result/improvement/${this.weakness}`)
    // const response = await axios.get(`http://nushu-jobs-backend.test/api/result/personality/${this.result}`)
    // const improvementResponse = await axios.get(`http://nushu-jobs-backend.test/api/result/improvement/${this.weakness}`)
    this.content = response.data
    this.improvement = improvementResponse.data
  },
  data() {
    return {
      content: undefined,
      improvement: undefined
    }
  },
  props: {
    result: {
      type: String,
      required: true,
    },
    weakness: {
      type: String,
      required: true,
    }
  },
})
