import axios from 'axios'

export const state = () => ({
  questions: [],
  orientation: []
})

export const actions = {
  fetchQuestions: async (state) => {
    return axios.get(`https://jobs-backend.teamnushu.de/api/questions`)
      .then(response => {
        state.commit('storeQuestions', response.data)
      })
  },
  fetchOrientation: async (state) => {
    return axios.get(`https://jobs-backend.teamnushu.de/api/orientation`)
      .then(response => {
        state.commit('storeOrientation', response.data)
      })
  },
}

export const mutations = {
  storeQuestions: (state, data) => {
    state.questions = data
  },
  storeOrientation: (state, data) => {
    state.orientation = data
  },
}

export const getters = {
  all(state) {
    return state.questions
  },
  orientationAll(state) {
    return state.orientation
  },
  orientationById(state) {
    return (id) => {
      return state.orientation.find(question => question.id.toString() === id);
    }
  },
  byId(state) {
    return (id) => {
      return state.questions.find(question => question.id.toString() === id);
    }
  },
  currentOrientationIndex(state) {
    return (id) => {
      return state.orientation.findIndex(question => question.id.toString() === id);
    }
  },
  displayPositionOrientation(state) {
    return (id) => {
      return state.orientation.findIndex(question => question.id.toString() === id) + 1;
    }
  },
  currentIndex(state) {
    return (id) => {
      return state.questions.findIndex(question => question.id.toString() === id);
    }
  },
  displayPosition(state) {
    return (id) => {
      return state.questions.findIndex(question => question.id.toString() === id) + 1;
    }
  },
  totalAmount(state) {
    return state.questions.length
  },
  totalOrientationAmount(state) {
    return state.orientation.length
  },
  followingOrientationQuestion(state) {
    return (id) => {
      const index = state.orientation.indexOf(state.orientation.find(question => parseInt(question.id) === parseInt(id))) + 1
      const hit = state.orientation[index];
      if (index <= state.orientation.length) return hit
      return undefined
    }
  },
  followingQuestion(state) {
    return (id) => {
      const index = state.questions.indexOf(state.questions.find(question => parseInt(question.id) === parseInt(id))) + 1
      const hit = state.questions[index];
      if (index <= state.questions.length) return hit
      return undefined
    }
  },
  previousQuestion(state) {
    return (id) => {
      const index = state.questions.indexOf(state.questions.find(question => parseInt(question.id) === parseInt(id))) - 1
      const hit = state.questions[index];
      if (index <= 0) return hit
      return undefined
    }
  }
}
