





















import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import AssessmentResult from '@/components/AssessmentResult.vue'
import Button from '@/components/Button.vue'

export default Vue.extend({
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (vm.$store.getters['user/orientationAnswers'].length === 0) {
        next('/orientation/1')
      }
    })
  },
  components: {
    AssessmentResult,
    Button,
  },
  data() {
    return {
      current: {},
      position: null,
      questionId: ''
    }
  },
  computed: {
    ...mapGetters('questions', [
      'byId',
      'displayPosition',
      'totalAmount',
      'followingQuestion'
    ]),
    ...mapGetters('user', [
      'answers'
    ]),
  },
  created() {
    this.questionId = this.$route.params.id
    this.current = this.byId(this.questionId)
  },
  methods: {
    ...mapActions('user', [
      'submitAnswer',
      'calculateResult',
    ]),
    getImageUrl(questionId: String) {
      const baseUrl = 'https://jobs-frontend.teamnushu.de/questions/';
      const fileName = `question-${('0' + questionId).slice(-2)}.png`;
      return `${baseUrl}/${fileName}`;
    },
    submit(value: String) {
      if (!this.followingQuestion(this.questionId)) {
        this.submitAnswer({
          value,
          question: this.current
        }).then(() => {
          this.calculateResult({
            value
          }).then(() => {
            this.$router.push({
              path: '/result',
            })
          })
        })
      } else {
        this.submitAnswer({
          value,
          question: this.current
        }).then(() => {
          this.$router.push({
            path: this.followingQuestion(this.questionId).id.toString(),
          })
        })
      }
    }
  }
})
