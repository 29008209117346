







import Vue from 'vue'
import NewsletterPopup from '@/components/NewsletterPopup.vue'

export default Vue.extend({
  middleware: ['questions', 'orientation'],
  components: {
    NewsletterPopup
  },
  data() {
    return {
      isNewsletterOpen: false
    }
  },
  created() {
    this.$sentry.configureScope(scope => {
      scope.setExtra('store', this.$store)
    })
  },
  mounted() {
    // @ts-ignore
    const hasSeenJobModal = this.$cookies.get('jobs-jobletter-seen')
    if (!hasSeenJobModal) {
      setTimeout(() => {
        this.isNewsletterOpen = true
      }, 1000)
    }
  },
  methods: {
    closeNewsletterModal() {
      const expires = new Date()
      expires.setDate(expires.getDate() + 1)
      // @ts-ignore
      this.$cookies.set('jobs-jobletter-seen', 'true', {
        expires,
        path: '/',
        maxAge: 60 * 60 * 24
      })
      this.isNewsletterOpen = false
    }
  }
})
