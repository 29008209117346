//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import Button from '@/components/Button.vue'
import RichText from '@/components/RichText.vue'
import Illustration from '@/components/Illustration.vue'

export default Vue.extend({
  components: {
    Button,
    Illustration,
    RichText
  }
})
